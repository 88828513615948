import React from "react"
import styled from "@emotion/styled"
import { theme } from "../particles/theme"

// colorblock content alignment isn't perfectly future-proof (if really long names, or tons of idh/max numbers)
// but should be sufficient for now
const StyledColorBlock = styled.section`
  font-size: 1.4rem;
  border-bottom: 1px solid black;
  padding-bottom: 1.5rem;
  position: relative;
  ${props =>
      props.color_name ?
      `height: 275px;` :
      `height: 225px;`
    }

  p {
    margin: 0;
    strong {
      color: #666;
    }
  }

  p.color-name {
    margin-bottom: 1.5rem;
    height: 25px;
  }

  div.color-info {
    height: 60px;
  }

  section {
    height: 100px;
    width: 100%;
    margin-bottom: 1.5rem;
    position: relative;
    box-sizing: border-box;

    ${props =>
      props.clear
        ? `
      &::after {
        content: "Clear";
        position: absolute;
        left: 1rem;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 700;
        top: calc(50px - 1rem);
        color: ${theme.colors.theHomeDepot.gray};
      }
    `
        : undefined}
  }

  a, button {
    width: 100%;
    background: ${theme.colors.theHomeDepot.primary};
    display: inline-block;
    text-decoration: none;
    padding: 1rem 0;
    color: #fff;
    font-size: 1.8rem;
    text-align: center;
    cursor: pointer;
    box-sizing: content-box;
    margin: 1rem 0 0;
    border: 0;
    border-bottom: 3px solid ${theme.colors.theHomeDepot.hover};
    transition: 0.3s;
    bottom: 15px;
  }

  a:hover, button:hover {
    background: ${theme.colors.theHomeDepot.hover};
  }
`

const ColorBlock = ({ osi, name, idh, idhmax, rgb, color_name, link, btn, unavailable, border }) => {
  if(idh) {
    idh = idh.toString().split(', ');
    idh = [...new Set(idh)];
    idh = idh.join(', ');
  }
  if(idhmax) {
    idhmax = idhmax.toString().split(', ');
    idhmax = [...new Set(idhmax)];
    idhmax = idhmax.join(', ');
  }
  return (
    <StyledColorBlock color_name={color_name} clear={rgb.toUpperCase() === "FFFFFF" ? 1 : 0}>
      <section style={{ background: `#${rgb}`, borderStyle: `${border}`, borderWidth: '1px' }} />
      {color_name ? <p className="color-name">{color_name}</p> : ""}
      <div className="color-info">
      <p>
        <strong>LePage&reg; Colour Code:</strong> {name} {osi}
      </p>
      {idh ? <p><strong>QUAD IDH#:</strong> {idh}</p>: ""}
      {(idhmax && unavailable) ? <strong style={{color:'#c8102e'}}><p>QUAD MAX IDH#: {idhmax}</p>
                                 <p>(Temp. Unavailable)</p></strong>
                               : idhmax ? <p><strong>QUAD MAX IDH#:</strong> {idhmax}</p> : ""
      }
      </div>
      <div>
        <a href={link} onClick={() => { let dataLayer = window.dataLayer || []; dataLayer.push({ event: `IDH: ${idh}` }) }}>
          {btn}
        </a>
      </div>
    </StyledColorBlock>
  )
}

export default ColorBlock
